import { getIdentifier, logErrorEvent } from 'react-commons';
import posthog from 'posthog-js';
import { action, actionSet, createStoreContext, CreateStoreOptions } from '@twocatmoon/react-actions';

import { GameData } from '@/lib/drupal/models/Games';
import axiosInstance from '../api/axiosInstance';
import authAxiosInstance from '../api/authAxiosInstance';
import AuthModel, { AuthData, AuthStatus } from '../models/Auth';
import UsersModel, { UserData } from '../models/Users';
import { destroyAllAdUnits } from '@/components/playwire/Playwire';



// #region Constants

const NUM_USER_GAMES = 16;

// #endregion



// #region Utilities

function setToken (token: string) {
  axiosInstance.defaults.headers[ 'X-CSRF-Token' ] = token;
  authAxiosInstance.defaults.headers[ 'X-CSRF-Token' ] = token;
}

function clearToken () {
  delete axiosInstance.defaults.headers[ 'X-CSRF-Token' ];
  delete authAxiosInstance.defaults.headers[ 'X-CSRF-Token' ];
}

// #endregion



// #region Schema & State

export interface AuthState {
  user: UserData | null
  userFavoriteGames: GameData[]
  auth: AuthData | null
  ready: boolean
}

const initialState = {
  user: null,
  userFavoriteGames: [],
  auth: null,
  token: null,
  ready: false,
};

// #endregion



// #region Actions

export const authActions = {
  setAuth: action<AuthState, AuthData>((prevState, auth) => {
    return {
      ...prevState,
      auth,
      ready: false,
    };
  }),

  setReady: action<AuthState, boolean>((prevState, ready) => {
    return {
      ...prevState,
      ready,
    };
  }),

  verifyAuth: action<AuthState, { user: AuthState['user'],  userFavoriteGames: GameData[], token: string }>((prevState, { 
    user,
    userFavoriteGames,
    token,
  }) => {
    posthog.identify(
      user.uid,
      { 
        email: user.email, 
        name: user.displayName,
        subscriber_status: user.isPremiumUser ? 'premium' : 'free',
      }
    );

    if (user.isPremiumUser) {
      destroyAllAdUnits();
    }

    return {
      ...prevState,
      user,
      userFavoriteGames: userFavoriteGames.slice(0, NUM_USER_GAMES),
      token,
      ready: true,
    };
  }),

  refreshUser: action<AuthState, { user: AuthState['user'], userFavoriteGames: GameData[], forcePremium?: boolean }>((prevState, { 
    user,
    userFavoriteGames,
    forcePremium,
  }) => {
    posthog.identify(
      user.uid,
      { 
        email: user.email, 
        name: user.displayName,
        subscriber_status: user.isPremiumUser ? 'premium' : 'free',
      }
    );

    if (forcePremium) {
      user.isPremiumUser = true;
    }

    if (user.isPremiumUser) {
      destroyAllAdUnits();
    }
    
    return {
      ...prevState,
      user,
      userFavoriteGames: userFavoriteGames.slice(0, NUM_USER_GAMES),
    };
  }),

  reset: action<AuthState, undefined>(() => {
    return {
      user: null,
      userFavoriteGames: [],
      auth: null,
      token: null,
      ready: true
    };
  }),

  setTokens: action<AuthState, { csrfToken: string, logoutToken: string }>((prevState, { csrfToken, logoutToken }) => {
    return {
      ...prevState,
      auth: {
        ...prevState.auth,
        csrfToken,
        logoutToken,
      },
    };
  }),

  setLayoutPreference: action<AuthState, 'classic' | 'modern'>((prevState, layoutPreference) => {
    return {
      ...prevState,
      user: {
        ...prevState.user,
        layoutPreference,
      },
    };
  }),
};

// #endregion



// #region Action Sets

export enum AuthError {
  InvalidCredentials = 'Your username or password is incorrect.',
  TokenError = 'There was an error while logging you in. Please try again later.',
  UserError = 'There was an error while fetching your profle. Please try again later.',
  VerificationError = 'Unable to verify your credentials. Please try again later.',
  DeauthorizeError = 'There was an error logging you out. Please try again later.',
}

interface AuthorizePayload {
  username: string
  password: string
  onSuccess?: (auth: AuthData) => void
  onError?: (error: AuthError) => void
}

interface VerifyPayload {
  onSuccess?: (result: { user: AuthState['user'], token: string }) => void
  onError?: (error: AuthError) => void
}

interface DeauthorizePayload {
  onSuccess?: () => void
  onError?: (error: AuthError) => void
}

interface RefreshUserPayload {
  forcePremium?: boolean
  onSuccess?: (user: UserData) => void
  onError?: (error: AuthError) => void
}

export const authActionSets = {
  authorize: actionSet<AuthState, AuthorizePayload>(async (dispatch, state, options) => {
    const error = async (err) => {
      if (options?.onError) options.onError(err);
      clearToken();
      dispatch(authActions.reset());
    };

    let loginResult: AuthData;
    try {
      loginResult = await AuthModel.login(options.username, options.password);
    } catch {
      try {
        const status = await AuthModel.status(); 
        if (status.logout_token) {
          try {
            await AuthModel.logout(status.logout_token);
            loginResult = await AuthModel.login(options.username, options.password);
          } catch (err) {
            error(AuthError.InvalidCredentials);
            return;
          }
        } else {
          error(AuthError.InvalidCredentials);
          return;
        }
      } catch (err) {
        error(AuthError.InvalidCredentials);
        return;
      }
    }

    setToken(loginResult.csrfToken);
    dispatch(authActions.setAuth(loginResult));

    if (options?.onSuccess) options.onSuccess(loginResult);
  }),

  verify: actionSet<AuthState, VerifyPayload>(async (dispatch, state, options) => {
    const error = async (err) => {
      if (options?.onError) options.onError(err);
      await logErrorEvent('Error Verifying Auth', true, err);
      window.location.reload();
    };

    dispatch(authActions.setReady(false));

    let status: AuthStatus;
    try {
      status = await AuthModel.status();
    } catch (err) {
      error(AuthError.VerificationError);
      dispatch(authActions.setReady(true));
      return;
    }

    if (!status.login_status) {
      clearToken();
      dispatch(authActions.reset());
      dispatch(authActions.setReady(true));
      return;
    }
    setToken(status.csrf_token);

    let user: UserData;
    try {
      user = await UsersModel.getById(status.user.uid, true);
      if (!user) {
        window.location.reload();
        return;
      }
    } catch (err) {
      error(AuthError.UserError);
      dispatch(authActions.setReady(true));
      return;
    }

    let userFavoriteGames: GameData[] = [];
    try {
      const [ 
        userFavoriteGamesResult 
      ] = await Promise.all([
        UsersModel.getFavoriteGamesForUser(status.user.uid, NUM_USER_GAMES, 0),
      ]);

      if (userFavoriteGamesResult) userFavoriteGames = userFavoriteGamesResult;
    } catch (err) {
      logErrorEvent('Error Fetching Initial Favorite Games for User', false, err);
    }

    dispatch(authActions.setAuth({
      userId: status.user.uid,
      roles: status.user.roles,
      csrfToken: status.csrf_token,
      logoutToken: status.logout_token
    }));
    dispatch(authActions.verifyAuth({ 
      user,
      userFavoriteGames,
      token: status.csrf_token,
    }));
    dispatch(authActions.setReady(true));

    if (options?.onSuccess) options.onSuccess({ user, token: status.csrf_token });
  }),

  refreshToken: actionSet<AuthState, undefined>(async (dispatch) => {
    let status;
    try {
      status = await AuthModel.status(); 
    } catch (err) {
      throw err;
    }

    setToken(status.csrf_token);
    dispatch(authActions.setTokens({
      csrfToken: status.csrf_token,
      logoutToken: status.logout_token,
    }));
  }),

  deauthorize: actionSet<AuthState, DeauthorizePayload>(async (dispatch, state, options) => {
    dispatch(authActions.setReady(false));

    if (state.auth.logoutToken) {
      try {
        await AuthModel.logout(state.auth.logoutToken);
      } catch {
        if (options?.onError) options.onError(AuthError.DeauthorizeError);
      }
    }

    dispatch(authActions.reset());
    dispatch(authActions.setReady(true));
    clearToken();

    if (options?.onSuccess) options.onSuccess();
  }),

  refreshUser: actionSet<AuthState, RefreshUserPayload>(async (dispatch, state, options) => {
    let user: UserData;
    let userFavoriteGames: GameData[] = [];
    try {
      const [ 
        userResult, 
        userFavoriteGamesResult 
      ] = await Promise.all([
        UsersModel.getById(state.auth.userId, true),
        UsersModel.getFavoriteGamesForUser(state.auth.userId, NUM_USER_GAMES, 0),
      ]);

      user = userResult;
      if (userFavoriteGamesResult) userFavoriteGames = userFavoriteGamesResult;
    } catch (err) {
      if (options?.onError) options.onError(AuthError.UserError);
      return;
    }

    dispatch(authActions.refreshUser({
      user,
      userFavoriteGames,
      forcePremium: options?.forcePremium,
    }));

    if (options?.onSuccess) options.onSuccess(user);
  }),
};

// #endregion



// #region Export

const options: CreateStoreOptions = {
  storageKey: getIdentifier('auth'),
  storageType: 'local',
  ssr: true,
};

export const { 
  Provider: AuthProvider, 
  useStore: useAuthStore 
} = createStoreContext<AuthState>(
  initialState, 
  authActions, 
  options
);

// #endregion
